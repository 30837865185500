@import "src/assets/scss/base.scss";

.landing-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: fixed;
  background-position: 40% 60%;
  background-image: url("../../assets/img/banner-bg-4.jpg");

  .header {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h1 {
      font-weight: bolder;
      line-height: 1.25;
      border-radius: 5px;
      color: $text;
      text-align: center;
      font-size: 3em;
      text-shadow: 2px 2px 10px $darker;
      padding-top: 0;

      .gta {
        color: $primary;
        font-size: 1.1em;
      }
    }
  }

  .container {
    display: flex;
    justify-content: end;
    margin: 0;

    .img-header {
      width: 20em;
    }
  }

  .container {
    margin: auto;
  }
}

@media (max-width: $mobile) {
  .landing-start {
    .container {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .img-header {
          width: 17em;
          padding: 1em;
        }

        h1 {
          padding-top: 2em;
          font-size: 1.25em;
        }
      }
    }
  }

  #projects {
    padding-bottom: 8em !important;
    .projects-title {
      margin-top:0em !important;
      margin-bottom: 1em !important;
    }
  }
}

@media (min-width: 701px) and (max-width: $screen-hd) {
  .landing-start {
    .container {
      display: flex;
      flex-direction: column;

      .img-header {
        width: 16em;
        margin: auto;
        // padding: 3em;
        padding-bottom: 3em;
      }

      .header {
        margin: auto;

        h1 {
          font-size: 2em;
        }
      }
    }
  }

  #projects {
    padding-bottom: 8em !important;
    .projects-title {
      margin-top: 1em !important;
    }
  }
}

@media (min-width: 1281px) and (max-width: $screen-fhd) {
  .landing-start {
    background-position: 30% 60%;

    .header {
      h1 {
        font-size: 2.25em;
      }
    }

    .container {
      .img-header {
        width: 20em;
        padding: 2em;
        margin: auto;
      }
    }
  }
}

@media (min-width: 1921px) {
  .landing-start {
    padding: 8em !important;
    background-position: 40% 200%;
  }
}

.projects-title {
  margin-bottom: 1.5em;
}

#projects {
  padding-top: 10em;
  padding-bottom: 10em;
}

.portfolio {
  position: relative;
}

.portfolio[portfolio_in_view="1"] {
  position: relative;
  animation: slide-up 2.25s ease;
  opacity: 1;
}

.contact {
  position: relative;
  opacity: 0;
}

.contact[contact_in_view="1"] {
  position: relative;
  animation: slide-right 1.25s ease-out;
  opacity: 1;
}

.map-element {
  position: relative;
  opacity: 0;
}

.map-element[map_in_view="1"] {
  position: relative;
  animation: slide-left 1.75s ease;
  opacity: 1;
}

.section-photo {
  position: fixed;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
