@import "src/assets/scss/variables.scss";

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }

  h2 {
    font-size: 4em;
    font-weight: 700;
    color: $primary;
  }

  p,
  li,
  span {
    color: $text-dark;
  }

  p,
  li {
    font-size: larger;
  }

  a {
    color: $primary-dark;
  }

  a:hover {
    color: $contrast;
  }

  padding-top: $navbar-height;
  min-width: 1100px;

  .container {
    min-width: 1000px;
    height: 100%;
    margin: auto;
  }
}

// .container {
//   min-width: 950px !important;
//   max-width: 95vw !important;
//   height: 100%;
// }

.index-bottom {
  position: relative;
  z-index: -2;
}

.index-2 {
  position: absolute !important;
  z-index: 2 !important;
}

.index-1 {
  position: relative;
  z-index: 1 !important;
}

.index-0 {
  position: relative;
  z-index: 0 !important;
}

.fill-contrast {
  fill: $contrast;
}

.fill-secondary {
  fill: $secondary;
}

.fill-bright {
  fill: $bright;
}

.fill-brighter {
  fill: $off-white;
}

.fill-white {
  fill: $white;
}

.fill-primary {
  fill: $primary;
}

.fill-dark {
  fill: $dark;
}

.fill-off-white {
  fill: $off-white;
}

.section {
  min-height: 50vh;
  padding-top: 7em;
  padding-bottom: 7em;
}

.section.section-lg {
  min-height: 75vh;
  padding-top: 10em;
  padding-bottom: 10em;
}

.section.section-md {
  min-height: 60vh;
  padding-top: 5em;
  padding-bottom: 5em;
}

.section.section-sm {
  min-height: 10vh;
  max-height: 50vh;
  padding-top: 1em;
  padding-bottom: 1em;
}

.bg-bright {
  background-color: $bright;
}

.bg-dark {
  background-color: $dark;
}

.bg-brighter {
  background-color: $off-white;
}

.bg-gray {
  background-color: $gray;
}

.bg-contrast {
  background-color: $contrast;
}

.bg-secondary {
  background-color: $secondary !important;
}

.grid {
  display: grid;
}

.grid.cols-11 {
  grid-template-columns: 1fr 1fr;
}

.grid.cols-12 {
  grid-template-columns: 1fr 2fr;
}

.grid.cols-21 {
  grid-template-columns: 2fr 1fr;
}

.grid.cols-111 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid.rows-11 {
  grid-template-rows: 1fr 1fr;
}

.grid.rows-12 {
  grid-template-rows: 1fr 2fr;
}

.grid.rows-21 {
  grid-template-rows: 2fr 1fr;
}

.grid.grid-center {
  height: 100%;
  align-items: center;
  margin: auto;
}

.grid.grid-around {
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.max-height {
  margin: auto;
  height: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.bg-default {
  background-color: $default;
}

.bg-primary-d {
  background-color: darken($primary, 20%);
}

.bg-gradient-light {
  background: linear-gradient(
    35deg,
    $primary 0,
    $primary-light 100%
  ) !important;
}

.bg-gradient-light-inverse {
  background: linear-gradient(45deg, $off-white 0, $light-gray 80%) !important;
}

.bg-gradient-secondary-light {
  background: linear-gradient(35deg, $light-gray 0, $off-white 80%) !important;
}

.bg-gradient-secondary-light-inverse {
  background: linear-gradient(35deg, $off-white 0, $gray 80%) !important;
}

.bg-gradient-white-override {
  background: linear-gradient(35deg, $off-white 0, white 90%) !important;
}

.bg-gradient-white-inverse {
  background: linear-gradient(35deg, $white 10%, $off-white 100%) !important;
}

.bg-gradient-contrast {
  background: linear-gradient(
    35deg,
    $contrast 10%,
    darken($contrast, 10%) 100%
  ) !important;
}

@media (max-width: $screen-fhd) {
  body {
    padding-top: $navbar-height-fhd;
  }
  .container {
    min-width: 1300px;
  }
}

@media (max-width: $mobile) {
  html, body {
    min-width: auto;
    padding-top: $navbar-height-mobile;
    overflow-x: hidden !important;
    width: 100% !important;
  }

  .container {
    padding: 0;
    padding-bottom: 2em;
    min-width: 300px !important;
  }

  .section {
    margin: 0;
    padding: 5em 0 1em 0;
  }
  
  .section.section-lg {
    padding: 5em 0 5em 0;
  }
  
  .section.section-md {
    margin: 0;
    padding: 5em 0 1em 0;
  }
  
  .section.section-sm {
    margin: 0;
    padding: 5em 0 1em 0;
  }
    
  .section-sm {
        max-height: 100% !important;
      }

  h2 {
    margin: 0 !important;
  }
}

@media (min-width: $mobile) {
  .container {
    width: 1100px !important;
    min-height: 100%;
    padding: 0;
  }
}

@media (min-width: $screen-fhd) {
  .section.section-lg {
    min-height: 750px;
  }

  .section.section-md {
    min-height: 600px;
  }

  .section.section-sm {
    height: 350px;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .container {
    min-width: 1500px !important;
  }
}

@keyframes slide-up {
  from {
    bottom: -10rem;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slide-left {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes slide-right {
  from {
    right: 100%;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
