// @import "src/assets/scss/argon-design-system-react.scss";
// @import "src/assets/scss/argon-design-system/variables";
@import "src/assets/scss/base.scss";

// #services {
//   padding-bottom: 15em;
//   padding-top: 10em;
// }

.services-title {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5em;
}

.services-section.container {
  min-height: 100% !important;
  margin: auto;
}

.service-item{
  position: relative;
}

.separator {
  margin: auto;
  max-height: 1px;
  max-width: 80%;
  background-color: $gray;
}


.service-list-items {
  justify-content: center;

  .service-list-item {
    margin-right: 0.5rem;
    margin-left: 0.5rem;

    .service-img {
      object-fit: cover;
      width: 100%;
      height: 9rem;
      border-radius: 0.5rem;
    }
  }

  .service-item-title {
    margin-bottom: 0.75rem;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid $primary;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

.corner-poly {
  display: flex;
  height: 100%;
  align-items: end;
}

.services-heading {
  p {
    text-align: center;
  }

  .services-img-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // margin-top: 5em;

    .outer-frame {
      margin: 1em;
      div {
        width: 28em;
        height: 25em;

        .corner-poly {
          svg {
            height: 50px;
            width: 50px;
            polygon {
              fill: $primary;
              opacity: 80%;
            }
          }
        }

        .service-description {
          opacity: 0;
          height: 100%;
          position: absolute;
          font-size: large;
          color: $text;
          font-weight: 500;
          margin: 1.4em;
          margin-top: 4em;
          line-height: 1.25;
          text-align: left;
          text-shadow: 1px 1px 10px $darker;
          transition: opacity $transition-fast;
          z-index: 1;
        }

        h3 {
          height: inherit;
          width: inherit;
          position: absolute;
          font-size: xx-large;
          color: $off-white;
          font-weight: bold;
          margin: 0.75em;
          transition: all $transition-fast;
          z-index: 1;
          text-shadow: 3px 3px 5px $dark;
        }

        .dark-fill {
          position: absolute;
          background-color: $darker;
          transition: opacity $transition-fast;
          opacity: 0;
          z-index: 0;
        }
      }
    }

    .service-item:hover {
      h3 {
        transition: all $transition-v-fast;
        color: $primary;
      }

      .service-description {
        opacity: 1;
        transition: opacity $transition-fast;
      }

      .dark-fill {
        transition: opacity $transition-fast !important;
        opacity: 85% !important;
      }
    }

    .bg-blow-in {
      background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
        center / cover no-repeat url("../../../assets/img/blow-in.jpg");
    }

    .bg-spray {
      background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
        center no-repeat url("../../../assets/img/sprayfoam.jpeg");
    }
    .bg-insulation {
      background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
        center / cover no-repeat url("../../../assets/img/insulation.jpeg");
    }
  }

  .services-img-section.cols-11 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $screen-hd) and (max-width: $screen-fhd) {
  .services-section {
    .services-heading {
      .services-img-section {
        .outer-frame {
          margin: 0.5em;
          div {
            width: 380px;
            height: 380px;

            h3 {
              margin: 0.2em 0 0.2em 0.5em;
            }

            .service-description {
              margin: 3em 0.2em 1em 1em;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $mobile) and (max-width: $screen-hd) {
  .services-section {
    .services-heading {
      .services-img-section {
        .outer-frame {
          div {
            width: 25em;
            height: 30em;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .services-title {
    width: 100%;
    text-align: center;
    padding-bottom: 1em;
  }

  .services-section {
    .services-heading {
      .services-img-section {
        display: flex;
        flex-direction: column;
        .outer-frame {
          margin: 0;
          padding-bottom: 3em;
          width: 100%;

          div {
            width: 100%;

            .service-description {
              font-size: medium;
              font-weight: 600;
            }
    
            h3 {
              font-size: x-large;
              margin-left: 1em;
            }
          }
        }
      }
    }
  }
}