@import "src/assets/scss/base.scss";

#about-us {
  padding-top: 10em;
  padding-bottom: 10em;
}

.intro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .details {
    position: relative;

    .label {
      font-size: 3em;
      font-weight: bold;
      color: $primary;
      font-variant: small-caps;
    }

    p {
      padding-left: 1em;
      color: $text !important;
      font-weight: 600;
    }
  }

  .intro-header {
    margin: auto;
    width: 38.85em;
    

    h2 {
      font-size: 18em;
      font-weight: bolder;
      letter-spacing: -0.05em;
      line-height: 0.75;
      border-radius: 5px;
      color: transparent !important;
      -webkit-text-stroke: 10px $primary;
    }
  }

  padding-top: 5em;
  padding-bottom: 5cqb;
}

#why[intro_in_view="0"] {
  opacity: 0;
}

#why[intro_in_view="1"] {
  position: relative;
  animation: slide-right 1.5s cubic-bezier(0.3, 0.9, 0.25, 1.1);
}

#us[intro_in_view="0"] {
  opacity: 0;
}

#us[intro_in_view="1"] {
  position: relative;
  animation: slide-right 2s cubic-bezier(0.7, 1, 0.75, 1.1);
}

@media (max-width: $mobile) {
  .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .details {
      padding-top: 5em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;

      div {
        padding-top: 1em;
        max-width: 100%;

        .label {
          font-size: 1.5em;
          line-height: 1;
          font-weight: bold;
          color: $primary;
          font-variant: small-caps;
        }

        p {
          padding-left: 0;
          line-height: 1.5;
          color: $text !important;
          font-weight: 600;
        }
      }
    }

    .intro-header {
      width: 100%;
      justify-content: space-evenly;
      margin: 0;

      h2 {
        font-size: 9em;
        font-weight: bold;
        color: $primary !important;
        -webkit-text-stroke: 0;
      }
    }

    padding-top: 5em;
    padding-bottom: 5em;
  }
}

@media (min-width: $mobile) and (max-width: $screen-hd) {
  .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .details {
      padding-top: 3em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;

      div {
        padding-top: 1em;
        max-width: 100%;

        .label {
          font-size: 2em;
          line-height: 1;
          font-weight: bold;
          color: $primary;
          font-variant: small-caps;
        }

        p {
          line-height: 1.5;
          color: $text !important;
          font-weight: 500;
        }
      }
    }

    .intro-header {
      width: 30em;
      justify-content: space-evenly;
      margin: 0;

      p {
        font-size: 10em;
        font-weight: bolder;
        letter-spacing: -0.05em;
        line-height: 0.7;
        -webkit-text-stroke: 5px $primary;
      }
    }

    padding-top: 5em;
    padding-bottom: 5em;
  }
}

@media (min-width: 1000px) and (max-width: 1600px) {
  .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .details {
      padding-top: 5em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;

      div {
        max-width: 50%;
        padding-left: 2em;
        padding-right: 2em;

        .label {
          font-size: 2em;
          font-weight: bold;
          color: $primary;
          font-variant: small-caps;
        }

        p {
          padding-left: 1em;
          line-height: 1.5;
          color: $text !important;
          font-weight: 600;
        }
      }
    }

    .intro-header {
      width: 40.25em;
      justify-content: space-evenly;
      // margin: 0;

      h2 {        
        letter-spacing: -0.025em;
      }

      p {
        font-size: 10em;
        font-weight: bolder;
        letter-spacing: -0.05em;
        line-height: 0.75;
        border-radius: 5px;
        color: transparent !important;
        -webkit-text-stroke: 5px $primary;
      }
    }

    padding-top: 5em;
    padding-bottom: 5cqb;
  }
}

@media (min-width: $screen-fhd) {
  .intro {
    .intro-header {
      margin-right: 7em;
      margin-left: -8em;

      p {
        font-size: 18em;
      }
    }
  }
}

@media (min-width: $screen-qhd) {
  .intro {
    .intro-header {
      margin-right: 7em;
      margin-left: -20em;

      p {
        font-size: 25em;
      }
    }
  }
}
