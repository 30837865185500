
$blue: #5e72e4 !default;
$indigo: #5603ad !default;
$purple: #8965e0 !default;
$pink: #f3a4b5 !default;
$red: #ff2020 !default;
$orange: #dd7717 !default;
$lightorange: #f1b072 !default;
$darkorange: #af5500 !default;
$yellow: #ffd600 !default;
$green: #0aa012 !default;
$teal: #11cdef !default;
$cyan: #2bffc6 !default;

$white: #ffffff !default;
$off-white: #f6f9fc !default;
$light-gray: #e0e0e0 !default;
$gray: #c4c4c4 !default;
$dark-gray: #2c2c2c !default;
$black: #000 !default;

$default: #292929 !default;
$primary: #ff8400 !default;
$primary-light: #fdd1a1 !default;
$primary-dark: #dd7717 !default;
$secondary: #44BBA4 !default;
$contrast: #3F88C5 !default;
$bright: #F6F7EB !default;
$success: $green !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray !default;
$dark: $dark-gray !default;
$darker: darken($dark-gray, 5%) !default;

$text: $bright;
$text-dark: $default;
$heading: $primary;
$subtitle: $contrast;

$transition-v-fast: .25s;
$transition-fast: .5s;
$transition-med: 1s;

$navbar-height-mobile: 1em;
$navbar-height: 5em;
$navbar-height-hd: 3em;
$navbar-height-fhd: 60px;

$mobile: 700px;
$screen-hd: 1280px;
$screen-fhd: 1920px;
$screen-qhd: 2560px;

$min-margin-qhd: 23em;
$min-margin-fhd: 9em;
$min-margin: 1em;
