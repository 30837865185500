@import "../../views/Main/Main.scss";

.logo-col {
  height: 10em;

  .nav-brand {
    width: 100%;
    height: 100%;
    .nav-logo {
      padding: 0.5em 1em 0.5em 1em;
      min-height: 100%;
    }
  }
}

.icon-col {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .icon-wrapper {
    display: flex;
    justify-content: space-evenly;

    .fa-icon {
      align-self: center;
      height: 3em;
    }
  }
}

.footer {
  background-color: $light-gray !important;
  margin-bottom: -2em;

  p {
    margin-bottom: 0;
  }

  h6 {
    margin-bottom: 0;
    // margin-top: 1em;
    font-weight: bold;
    font-size: large;
    color: $default;
  }

  .container {
    padding-top: 1em;
    margin-bottom: -1rem;
  }

  .footer-separator {
    border-color: $gray;
  }

  .info-separator {
    border-color: $gray;
  }

  .footer-email {
    color: $dark-gray;
  }

  .footer-text {
    font-size: medium;
    font-weight: 500;
  }

  .info-column {
    .info-title {
      height: 100%;
      margin: auto;
      font-weight: 700;
    }
  }
}

@media (max-width: $screen-fhd) {
  .nav-brand {
    max-width: 18em;
    min-height: 100% !important;
    padding-right: 6em;

    .nav-logo {
      width: 100%;
      height: auto;
    }
  }

  .icon-col {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .icon-wrapper {
      display: flex;
      justify-content: space-evenly;

      .fa-icon {
        align-self: center;
        height: 2.5em;
      }
    }
  }
}

@media (max-width: $screen-hd) {
  .nav-brand {
    min-height: 100%;
    padding-right: 5em;
    margin-right: 0;

    .nav-logo {
      width: 100%;
    }
  }

  .icon-col {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .icon-wrapper {
      display: flex;
      justify-content: space-evenly;

      .fa-icon {
        align-self: center;
        height: 2em;
      }
    }
  }
}

@media (max-width: $mobile) {
  .footer {
    margin-bottom: -2em;
    background-color: $light-gray !important;

    h6 {
      margin-bottom: 0;
      font-weight: bold;
      font-size: large;
      color: $default;
    }

    .container {
      .follow-col {
        padding-top: 2em;
        justify-content: center;
        text-align: center;
        .icon-col {
          padding-top: 2em;
          padding-bottom: 2em;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;

          .icon-wrapper {
            .fa-icon {
              height: 2em;
            }
          }
        }
      }

      margin-bottom: -1rem;
      .footer-separator {
        border-color: $gray;
      }

      .footer-email {
        color: $dark-gray;
      }

      .footer-text {
        font-size: medium;
        font-weight: 500;
      }

      .info-column {
        .row {
          flex-direction: column;
        }

        .info-title {
          margin-top: 1em;
          font-weight: 700;
        }
      }

      .copyright {
        padding-bottom: 0;
      }
    }
  }
}

@media (min-width: $screen-fhd) {
  .nav-brand {
    max-width: 30em;
    min-height: 100% !important;
    padding-right: 18em;

    .nav-logo {
      width: 100%;
      height: auto;
    }
  }
}